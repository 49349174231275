<template>
  <div class="banner-container" ref="container">
    <img
      class="bg"
      src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202203141805152971834.gif"
      alt=""
    />
    <div
      class="active-logo"
      :style="{ marginTop: isArrangePage ? '0' : '-45px' }"
    >
      <!-- <img
        src="https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104152218310739777.png"
        alt=""
      /> -->
      <!-- <div class="cloud"></div> -->
    </div>
    <img
      class="logo"
      src="https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202203091835178896735.png"
      alt=""
    />
    <canvas id="canvas" ref="canvas"></canvas>
  </div>
</template>

<script>
export default {
  name: "banner",
  props: {
    active: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.active) {
      this.$nextTick(() => {
        this.initCanvas();
      });
    }
  },
  computed: {
    isArrangePage() {
      return this.$route.path.includes("arrangeDay");
    },
  },
  methods: {
    initCanvas() {
      // Init Context
      let c = this.$refs.canvas.getContext("2d");
      let postctx = document.body
        .appendChild(document.createElement("canvas"))
        .getContext("2d");
      let canvas = c.canvas;
      let vertices = [];

    
      // Effect Properties
      let vertexCount = 7000;
      let vertexSize = 3;
      let oceanWidth = 300;
      let oceanHeight = -80;
      let gridSize = 30;
      let waveSize = 30;
      let perspective = 100;

      // Common variables
      let depth = (vertexCount / oceanWidth) * gridSize;
      let frame = 0;
      let { sin, cos,  PI } = Math;

      // Render loop
      let loop = () => {
        let rad = (sin(frame / 100) * PI) / 20;
        let rad2 = (sin(frame / 50) * PI) / 10;
        frame++;
        canvas.width = this.$refs.container.clientWidth
        canvas.height = this.$refs.container.clientHeight
        // if (
        //   postctx.canvas.width !== postctx.canvas.offsetWidth ||
        //   postctx.canvas.height !== postctx.canvas.offsetHeight
        // ) {
        //   postctx.canvas.width = canvas.width = postctx.canvas.offsetWidth;
        //   postctx.canvas.height = canvas.height = postctx.canvas.offsetHeight;
        // }

        c.fillStyle = `rgba(0,0,0,0)`;
        c.fillRect(0, 0, canvas.width, canvas.height);
        c.save();
        c.translate(canvas.width / 2, canvas.height / 2);

        c.beginPath();
        vertices.forEach((vertex, i) => {
          // let ni = i + oceanWidth;
          let x = vertex[0] - (frame % (gridSize * 2));
          let z =
            vertex[2] -
            ((frame * 2) % gridSize) +
            (i % 2 === 0 ? gridSize / 2 : 0);
          let wave =
            cos(frame / 45 + x / 50) -
            sin(frame / 20 + z / 50) +
            sin(frame / 30 + (z * x) / 10000);
          let y = vertex[1] + wave * waveSize;
          let a = Math.max(0, 1 - Math.sqrt(x ** 2 + z ** 2) / depth);
          let tx, ty, tz;

          y -= oceanHeight;

          // Transformation variables
          tx = x;
          ty = y;
          tz = z;

          // Rotation Y
          tx = x * cos(rad) + z * sin(rad);
          tz = -x * sin(rad) + z * cos(rad);

          x = tx;
          y = ty;
          z = tz;

          // Rotation Z
          tx = x * cos(rad) - y * sin(rad);
          ty = x * sin(rad) + y * cos(rad);

          x = tx;
          y = ty;
          z = tz;

          // Rotation X

          ty = y * cos(rad2) - z * sin(rad2);
          tz = y * sin(rad2) + z * cos(rad2);

          x = tx;
          y = ty;
          z = tz;

          x /= z / perspective;
          y /= z / perspective;

          if (a < 0.01) return;
          if (z < 0) return;

          c.globalAlpha = a;
          c.fillStyle = `hsl(${180 + wave * 20}deg, 100%, 50%)`;
          c.fillRect(
            x - (a * vertexSize) / 2,
            y - (a * vertexSize) / 2,
            a * vertexSize,
            a * vertexSize
          );
          c.globalAlpha = 0;
        });
        c.restore();

        // Post-processing
        postctx.drawImage(canvas, 0, 0);

        postctx.globalCompositeOperation = "screen";
        postctx.filter = "blur(16px)";
        postctx.drawImage(canvas, 0, 0);
        postctx.filter = "blur(0)";
        postctx.globalCompositeOperation = "source-over";

        requestAnimationFrame(loop);
      };

      // Generating dots
      for (let i = 0; i < vertexCount; i++) {
        let x = i % oceanWidth;
        let y = 0;
        let z = (i / oceanWidth) >> 0;
        let offset = oceanWidth / 2;
        vertices.push([(-offset + x) * gridSize, y * gridSize, z * gridSize]);
      }

      loop();
    },
  },
};
</script>

<style lang="less" scoped>
.banner-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  background: #ccc;
  .bg {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
  .logo {
    position: absolute;
    left: 5px;
    top: 5px;
    height: 37px;
  }
  .active-logo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 2;
    img {
      animation: act 15s ease infinite;
      z-index: 2;
      height: 30px;
    }
    // img:nth-of-type(1){
    //   height: 34px;
    // }
    // img:nth-of-type(2){
    //   height: 13px;
    //   // animation-delay: 3s;
    // }
    @keyframes act {
      10% {
        transform: translate(20px, 5px);
      }
      20% {
        transform: translate(1px, 0px);
      }
      40% {
        transform: translate(0px, 10px);
      }
      60% {
        transform: translate(20px, 10px);
      }
      70% {
        transform: translate(-5px, 8px);
      }
      80% {
        transform: translate(5px, -2px);
      }
      100% {
        transform: translate(0, 0);
      }
    }
    .cloud {
      position: absolute;
      height: 50px;
      width: 200px;
      background: rgba(255, 255, 255, 0.5);
      filter: blur(40px);
      z-index: 1;
      animation: fade 5s linear infinite;
    }
    @keyframes fade {
      50% {
        transform: translate(2px, -1px);
        background: rgba(255, 255, 255, 0.8);
      }
      100% {
        transform: translate(0, 0);
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
  #canvas {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    // opacity: .2;
  }
}
</style>
