<template>
  <div class="full-h" style="z-index:1; position:relative;">
    <!-- 网站类 -->
    <div v-if="$store.state.themeInfo.type === 2" style="padding-top:4.4rem;" class="conten">
      <!-- <img :src="bg" alt="" class="bg" v-if="bg"> -->
      <div class="bg">
        <Banner />
      </div>
      <transition-group
        appear
        tag="div"
        class="conten menu-wraper"
      >
        <div
          class="menu-item"
          v-for="item in menuList"
          :key="item.id"
          @click="changeRouter(item.linkType, item.linkUrl)"
        >
        <!-- :style="{ width: menuList.length === 4 || menuList.length === 2 || menuList.length === 1 ? '50%' : '33.3%', marginBottom: menuList.length === 3 ? '40px' : '0' }" -->
          <img class="img" :src="item.iconUrl" alt="" v-if="item.iconType === 0">
          <van-icon class="img" :name="item.iconUrl" v-else-if="item.iconType === 1 && !item.iconUrl.startsWith('image-')" />
          <img class="img" :src="dealIcon(item.iconUrl)" v-else alt="">
          <span style="font-size:0.28rem">{{ item.name }}</span>
        </div>
      </transition-group>
    </div>
    <!-- 九宫格模板 -->
    <div v-else :class="{ templateOne: true, templateFour: template === 2, templateThree: template === 3, templateTwo: template === 4 }" ref="mainContent">
      <div class="main-bg" ref="mainBg">
        <van-swipe class="swipe-container" :autoplay="5000" :indicator-color="$store.state.themeInfo.themeColor">
          <van-swipe-item v-for="item in headBg" :key="item.url">
            <img :src="item.url" alt="" @click="bannerClick(item.link, item.linkType)">
          </van-swipe-item>
        </van-swipe>
      </div>
      <img v-if="mainBg" class="background-img" :src="mainBg" alt="">
      <transition appear name="grid">
        <div class="content-wrap">
          <div class="content">
            <van-button
              class="menuItem"
              v-for="item in menuList"
              :key="item.id"
              @click="changeRouter(item.linkType, item.linkUrl)"
            >
              <div class="img-box" ref="imageBox">
                <img class="img" :src="item.url" alt="" v-if="item.iconType === 0">
                <van-icon class="img" :name="item.iconUrl" v-if="item.iconType === 1 && !item.iconUrl.startsWith('image-')" />
                <img class="img" :src="dealIcon(item.iconUrl)" v-else alt="">
              </div>
              <span>{{ item.name }}</span>
            </van-button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
// import Banner from '@/components/banner/index'
// import Banner from '@/components/banner/index2'
import Banner from '@/components/banner/index1'
// import Banner from '@/components/banner/index3'
// import bus from '@/utils/bus'
export default {
  name: 'headMenu',
  components: { Banner },
  data() {
    return {
      menuList: [], // 菜单列表
      icons: {},
      bg: require('@/assets/images/menu-bg.png'),
      headBg: '',
      mainBg: '',
      colorList: [
        '#f6ac89',
        '#add9ee',
        '#ace4ca',
        '#bbe5b2',
        '#f7ce8c',
        '#bef18a',
        '#d1c0a5',
        '#74c3f4',
        '#aeb7e5'
      ]
    }
  },
  methods: {
    dealIcon(icon) {
      if (icon.startsWith('image-')) {
        return this.icons[icon.slice(6)]
      } else {
        return icon
      }
    },
    changeRouter(type, link) {
      if (type === 0) {
        if (link === 'show-test') {
          Toast({
            message: '敬请期待',
            position: 'middle'
          })
          this.$nextTick(() => {
            document.querySelector('.van-toast').style.background = 'rgb(255,255,255)'
            document.querySelector('.van-toast').style.color = '#333'
            document.querySelector('.van-toast').style.boxShadow = '0 0 5px 1px rgba(0,0,0,.05)'
          })
          return
        }  else if(link === 'user-list') {
          this.$router.push('/index/userList/' + this.$store.state.miceInfo.miceLink )
        }else if (link === 'net-enterprise') {
          this.$router.push('/enterprise/' + this.$store.state.miceInfo.miceLink)
          // bus.$emit('selectNav')
          this.$parent.$parent.selectedNav(false)
          return
        } else if (link === 'reception') {
          this.$router.push('/reception/' + this.$store.state.miceInfo.miceLink)
          return
        } else if (link === 'history') {
          this.$router.push('/historyMeeting/' + this.$store.state.miceInfo.miceLink)
          return
        }else if (link === 'liveTv') {
          this.$router.push('/liveTv/' + this.$store.state.miceInfo.miceLink)
          return
        }
        window.location.href = link
      } else {
        if (link.indexOf('/') !== -1) {
          if (link === '/stroke') {
            this.$router.push(`${link}/${this.$store.state.miceInfo.miceLink}/enter`)
          } else {
            this.$router.push(`${link}/${this.$store.state.miceInfo.miceLink}`)
          }
        } else {
          this.$router.push(`/selfpage/${link}/${this.$store.state.miceInfo.miceLink}`)
        }
      }
    },
    async getMenuList() {
      let result = await this.$http.get(this.$baseURL.index.getMenuList() + '/?miceLink=' + this.$store.state.miceInfo.miceLink, 1)
      if (result.code === 200) {
        this.menuList = []
        result.data.forEach((item, index) => {
          if (item.display === 1 && index < 9) {
            this.menuList.push(item)
          }
        })
        if (this.$store.state.themeInfo.type === 1) {
          this.menuList = this.menuList.slice(0,9)
        }
        if (this.template === 2 && this.menuList.length > 0) {
          this.initIcon()
        }
      } else {
        Dialog.alert({
          title: '提示',
          message: result.message,
        }).then(() => {
          // on close
        })
      }
    },
    initIcon() {
      this.$nextTick(() => {
        const imageBox = this.$refs.imageBox
        for (let i = 0; i < imageBox.length; i ++) {
          imageBox[i].style.background = this.colorList[i]
        }
      })
    },
    requireImage() {
      for (let i = 1; i <= 71; i ++) {
        this.icons[i + ''] = require(`../../../assets/images/blackIcon/${i}.png`)
      }
    },
    bannerClick(link, type) {
      if (link) {
        if (type === 1) {
          this.$router.push(`/selfpage/${link}/${this.$store.state.miceInfo.miceLink}`)
        } else if (type === 2) {
          if (link === '/stroke') {
            this.$router.push(`${link}/${this.$store.state.miceInfo.miceLink}/enter`)
          } else {
            this.$router.push(`${link}/${this.$store.state.miceInfo.miceLink}`)
          }
        } else {
          window.location.href = link
        }
      }
    }
  },
  computed: {
    template() {
      return this.$store.state.themeInfo.webType
    }
  },
  mounted() {
    if (this.$store.state.themeInfo.type === 2) {
      if (this.$store.state.themeInfo.bgUrl) {
        this.bg = this.$store.state.themeInfo.bgUrl
      }
    }
    if (this.template === 1) {
      this.mainBg = this.$store.state.themeInfo.bgUrl
    } else {
      this.headBg = JSON.parse(this.$store.state.themeInfo.bgUrl)
    }
    this.getMenuList()
    this.requireImage()
  }
}
</script>

<style lang="less" scoped>
.conten{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #fff;
  padding: 8px 9px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  .bg{
    position: absolute;
    top: 0;
    left: 0;
    height: 4.8rem;
    width: 100%;
    z-index: 0;
  }
  .menu-wraper{
    height: 4.2rem;
    background: rgba(255,255,255, .9);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    color: #3E3F53;
    box-shadow: 0 0 5px 1px rgba(0,0,0,.05);
  }
  .menu-item{
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    width: 25%;
    padding: 5px 0;
    span{
      font-weight: 500;
    }
    .img{
      height: 22px;
      width: 22px;
      color: #3E3F53;
      font-size: 22px;
      margin-bottom: 5px;
    }
  }
}

.main-bg{
  height: 5.4rem;
  object-fit: cover;
  img{
    height: 100%;
    width: 100%;
  }
}
.main-bg-contains{
  background: url('../../../assets/images/menu-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}
.content-wrap{
  display: flex;
  align-items: center;
  height: calc(100% - 6rem);
}
.content{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-wrap: wrap;
  width: 100%;
  padding: 1.2rem 0.4rem 0rem 0.4rem;
  z-index: 1;
}

.templateOne{
  position: relative;
  height: 100%;
  overflow: hidden;
  .background-img{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .menuItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,.15);
    font-size: 0.34rem;
    height: 2.6rem;
    width: 2.6rem;
    margin: 0.15rem;
    color: #3E3F53;
    border: none;
    span{
      font-weight: 500;
    }
    .img{
      height: 20px;
      width: 20px;
      color: #3E3F53;
      font-size: 30px;
      margin-bottom: 5px;
    }
  }
}

.templateTwo{
  .menuItem{
    background: transparent;
  }
}

.templateThree{
  background: #fff;
  .content-wrap{
    align-items: flex-start;
  }
  .content{
    padding: 0.3rem;
  }
  .menuItem{
    height: 2.6rem;
    width: 30%;
    flex-grow: 1;
    background: rgb(76,126,186);
    border-radius: 0.1rem;
  }
  .menuItem:nth-of-type(2), .menuItem:nth-of-type(6), .menuItem:nth-of-type(9){
    width: calc(60% + 0.3rem);
  }
  .menuItem:nth-of-type(3), .menuItem:nth-of-type(9){
    background: rgb(153,182,216);
  }
  .menuItem:nth-of-type(5){
    background: rgb(127, 163,207);
  }
}

.templateFour{
  background: #fff;
  .content{
    padding: 1.2rem 0.4rem 1rem 0.4rem;
  }
  .swipe-container{
    height: 100%;
  }
  .menuItem{
    background: transparent;
    color: #444;
    display: flex;
    height: 2.9rem;
    width: 2.9rem;
    margin: 0;
    .img-box{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      border-radius: 50%;
      height: 45px;
      width: 45px;
      .img{
        height: 27px;
        width: 27px;
        font-size: 27px;
        margin-bottom: 0;
      }
    }
    .van-button__text{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
/deep/ .van-button--normal{
  padding: 0;
}
/deep/ .van-toast--text{
  background-color: rgba(255, 255, 255, .7);
  color: #333;
}
.v-enter{
  transform: scale(.3);
  opacity: 0;
}
.v-enter-active{
  transition: all .5s ease;
}
.grid-enter{
  transform: translateY(160px);
  opacity: 0;
}
.grid-enter-active{
  transition: all .5s ease;
}
</style>